.ion-color-primary.btn{
  flex-grow: 1;
}

.btns{
  display: flex;
  width: auto;
  justify-content: center;
  align-items: flex-end;
}

.line-container{
  padding: 1rem;
}