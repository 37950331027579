.ion-color-primary.btn{
  flex-grow: 1;
}

.btns{
  display: "flex";
  width: "auto";
  justify-content: "space-between";
}

.content{
  text-align: center;
  padding-left: 1rem;
  padding-right: 1rem;
}