.no-margin{
  margin:0;
}
.h5{
  margin-bottom: 0;
  font-weight: bold;
}


.details-col{
  text-align: center;
}

.details-profilePic{
  background: 50% 50% no-repeat;
  border-radius: 99999px;
  width: 8rem;
  height: 8rem;
}
.edit-container{
  display: flex;
  align-items: center;
}