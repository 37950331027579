input {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0;
}
input:focus {
  background-color: rgba(0, 0, 0, 0) !important;
  border: 0;
}
.btn{
margin-top: 1rem;
}