.slotShell{
  --background: #00000000;

  --border-style: solid;
  --border-width: 2px;

  --border-radius: 1rem;
  margin-bottom: 1rem;
}

.empty{
  --background: rgba(0, 0, 0, 0);
  --background-color: rgba(0, 0, 0, 0);
  --border-width: 3px;
  --border-radius: 1rem;
  --border-color: #00000000;
  margin-bottom: 1rem;
}





